<template>
  <b-container>
    <div class="content">
      <b-card header="Dokumentkategorie erstellen">
        <b-form @submit.prevent="save">
          <b-row>
            <b-col>
              <label>Name der Kategorie</label>
              <b-input v-model="documentFileCategory.name"/>
            </b-col>
            <b-col>
              <label>Aktiv</label>
              <b-checkbox v-model="documentFileCategory.active">Aktiv</b-checkbox>
            </b-col>
          </b-row>
          <b-button variant="success" class="mt-2 float-right" type="submit">Speichern</b-button>
        </b-form>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import HTTP from "@/http";

export default {
  data() {
    return {
      id: null,
      documentFileCategory: {}
    }
  },
  created() {
    if (this.$route.params.id !== undefined) {
      this.id = this.$route.params.id;
      HTTP.get("/document-file-categories/" + this.id, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(result => this.documentFileCategory = result.data);

      this.loadTemplates();
    }
  },
  methods: {
    save: function () {
      if (this.id == null) {
        HTTP.post("/document-file-categories", this.documentFileCategory, {
          headers: {
            'Accept': 'application/json'
          }
        }).then(() => {
          this.$router.push({name: 'DocumentFileCategories'}).then(() => {
            this.$bvToast.toast('Kategorie erfolgreich gespeichert', {
              title: 'Gespeichert',
              variant: 'success'
            });
          })
        }).catch(() => {
          this.$bvToast.toast('Kategorie konnte nicht gespeichert werden', {
            title: 'Fehler',
            variant: 'danger'
          });
        })
        return;
      }

      HTTP.put("/document-file-categories/" + this.id, this.documentFileCategory, {
        headers: {
          'Accept': 'application/json'
        }
      }).then(() => {
        this.$router.push({name: 'DocumentFileCategories'}).then(() => {
          this.$bvToast.toast('Kategorie erfolgreich gespeichert', {title: 'Gespeichert', variant: 'success'});
        })
      }).catch(() => {
        this.$bvToast.toast('Kategorie konnte nicht gespeichert werden', {title: 'Fehler', variant: 'danger'});
      })
    }
  }
}
</script>
<style>

</style>