<template>
  <b-container>
    <div class="content">
      <b-card>
        <b-tabs>
          <b-tab title="Vorlagenkategorien">
            <b-button class="float-right mt-2" variant="success" :to="{name:'CreateTemplateCategory'}">
              Kategorie erstellen
            </b-button>
            <b-table class="card-table" striped hover :no-local-sorting="true" per-page="100"
                     :items="templateCategories.content"
                     :fields="categoryFields">
              <template #cell(color)="data" class="sm-col">
                <div class="colorCode" :style="'background-color: '+data.item.colorCode"></div>
              </template>
              <template #cell(actions)="data" class="sm-col">

                <b-button :to="{name:'UpdateTemplateCategory', params: {'id':data.item.id}}"
                          size="sm" variant="primary"
                          v-b-tooltip="'Klicken Sie hier um sich die Template Kategorie anzeigen zu lassen und zu bearbeiten.'">
                  <b-icon icon="pencil" font-scale="1"></b-icon>
                </b-button>
                <b-button @click="deleteTemplateCategory(data.item)"
                          size="sm" variant="danger" class="ml-2"
                          v-b-tooltip="'Klicken Sie hier um die Template Kategorie zu löschen.'">
                  <b-icon icon="trash" font-scale="1"></b-icon>
                </b-button>
              </template>
            </b-table>
            <RouterlessPageable v-if="templateCategories != null" :pageable="templateCategories" @change="loadTemplateCategories"/>
          </b-tab>
          <b-tab title="Vorlagen">
            <b-button class="float-right mt-2" variant="success" :to="{name:'CreateTemplate'}">
              Vorlage erstellen
            </b-button>
            <b-table class="card-table" striped hover :no-local-sorting="true" per-page="100" :items="templates.content"
                     :fields="templateFields">
              <template #cell(actions)="data" class="sm-col">
                <b-button :to="{name:'UpdateTemplate', params: {'id':data.item.id}}"
                          size="sm" variant="primary"
                          v-b-tooltip="'Klicken Sie hier um sich das Template anzeigen zu lassen und zu bearbeiten.'">
                  <b-icon icon="pencil" font-scale="1"></b-icon>
                </b-button>
                <b-button @click="download(data.item)"
                          size="sm" variant="success" class="ml-2"
                          v-b-tooltip="'Klicken Sie hier um den Template download zu starten.'">
                  <b-icon icon="download" font-scale="1"></b-icon>
                </b-button>
                <b-button @click="deleteTemplate(data.item)"
                          size="sm" variant="danger" class="ml-2"
                          v-b-tooltip="'Klicken Sie hier um das Template zu löschen.'">
                  <b-icon icon="trash" font-scale="1"></b-icon>
                </b-button>
              </template>
            </b-table>
            <RouterlessPageable v-if="templates != null" :pageable="templates" @change="loadTemplates"/>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </b-container>
</template>
<script>

import RouterlessPageable from "@/components/RouterlessPageable.vue";
import HTTP from "@/http";
import {itoc} from "core-js/internals/base64-map";

export default {
  components: {RouterlessPageable},
  data() {
    return {
      templates: {},
      templateCategories: {},
      templateFields: [
        {
          key: 'id',
          label: 'id'
        },
        {
          key: 'name',
          label: 'Name der Vorlage'
        },
        {
          key: 'sort',
          label: 'Sortierung'
        },
        {
          key: 'type',
          label: 'Typ'
        },
        {
          key: 'actions',
          label: 'Aktionen'
        }
      ],
      categoryFields: [
        {
          key: 'id',
          label: 'id'
        },{
          key: 'color',
          label: 'Farbe'
        },
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'sort',
          label: 'Sortierung'
        },
        {
          key: 'actions',
          label: 'Aktionen'
        }
      ]
    }
  },
  created() {
    this.loadTemplates();
    this.loadTemplateCategories();
  },
  methods: {
    itoc() {
      return itoc
    },
    loadTemplates: function (page = 0) {
      HTTP.get("/templates?sort=_id,desc&page=" + page + "&size=100", {
        headers: {
          'Accept': 'application/json'
        }
      }).then(response => {
        this.templates = response.data;
      });
    },
    loadTemplateCategories: function (page = 0) {
      HTTP.get("/template-categories?sort=_id,desc&page=" + page + "&size=100", {
        headers: {
          'Accept': 'application/json'
        }
      }).then(response => {
        this.templateCategories = response.data;
      });
    },
    download: function (item) {
      HTTP.get("/templates/data/" + item.id, {responseType: 'blob'}).then(response => {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = item.filename;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    deleteTemplate: function (item) {
      if (!confirm('Template wirklich löschen?')) {
        return;
      }
      this.templates.content.pop(item);
      HTTP.delete("/templates/" + item.id, {
        headers: {
          'Accept': 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast('Erfolgreich gelöscht', {title: 'Gelöscht'})
      })
    },
    deleteTemplateCategory: function (item) {
      if (!confirm('Kategorie wirklich löschen?')) {
        return;
      }
      this.templateCategories.content.pop(item);
      HTTP.delete("/template-categories/" + item.id, {
        headers: {
          'Accept': 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast('Erfolgreich gelöscht', {title: 'Gelöscht'})
      })
    }
  }
}
</script>
<style scoped>
.colorCode{
  width: 20px;
  height: 20px;
  margin: auto;
  display: block;
  border-radius: 2px;
}
</style>