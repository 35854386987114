<template>
  <b-container>
    <div class="content">
      <b-row>
        <b-col>
          <b-card class="text-center">
            <h4>Aktive</h4><br/>
            {{ stats.activeCount }}
          </b-card>
        </b-col>
        <b-col>
          <b-card class="text-center">
            <h4>Insgesamt</h4><br/>
            {{ stats.count }}
          </b-card>
        </b-col>
        <b-col>
          <b-card class="text-center">
            <h4>Gekündigte</h4><br/>
            {{ stats.canceledCount }}
          </b-card>
        </b-col>
        <b-col>
          <b-card class="text-center">
            <h4>30 Tage</h4><br/>
            {{ stats.last30DaysCount }}
          </b-card>
        </b-col>
      </b-row>
      <b-card header="Kunden (Chronologisch sortiert)">
        <b-table class="card-table" striped hover :no-local-sorting="true" :items="items" :fields="fields">
          <template #cell(active)="data">
            <b-checkbox switch v-model="data.item.active" @change="toggleUser(data.item)"/>
          </template>
          <template #cell(actions)="data" class="sm-col">
            <b-button :to="{name:'ShowCustomer', params: {'id':data.item.id}}"
                      size="sm" variant="primary"
                      v-b-tooltip="'Klicken Sie hier um sich den Kunden anzeigen zu lassen.'">
              <b-icon icon="eye" font-scale="1"></b-icon>
            </b-button>
          </template>
          <template #cell(created)="data">
            {{data.item.created | germanDateTime}}
          </template>
        </b-table>
        <RouterlessPageable v-if="users != null" :pageable="users" @change="loadUsers"/>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import VueJwtDecode from "vue-jwt-decode";
import HTTP from "@/http";
import RouterlessPageable from "@/components/RouterlessPageable";
import messageService from "@/services/message.service";

export default {
  name: 'Dashboard',
  components: {RouterlessPageable},
  data() {
    return {
      token: VueJwtDecode.decode(localStorage.getItem("token")),
      stats: {},
      fields: [
        {
          key: 'created',
          label: 'Registriert'
        },
        {
          key: 'prename',
          label: 'Vorname'
        },
        {
          key: 'lastname',
          label: 'Nachname'
        },
        {
          key: 'email',
          label: 'E-Mail'
        },
        {
          key: 'active',
          label: 'Aktiv'
        },
        {
          key: 'actions',
          label: 'Aktionen',
          class: 'sm-col'
        }
      ],
      items: [],
      users: null
    }
  },
  methods: {
    toggleUser: function (user) {
      HTTP.put("/users/active/" + user.id).then(() => {
        messageService.success("Toggle done", "Nutzer aktiviert/deaktiviert");
      })
    },
    loadUsers: function (page = 0) {

      HTTP.get("/users?sort=_id,desc&page=" + page, {
        headers: {
          'Accept' : 'application/json'
        }
      }).then(response => {
        this.items = response.data.content;
        this.users = response.data;
      });
    }
  },
  created() {
    this.loadUsers();
    HTTP.get("/admin/users").then(response => {
      this.stats = response.data;
    });
  }
}
</script>
<style scoped>

</style>