import axios from 'axios'
import router from "@/config/router";
import AuthService from '@/services/auth.service';
import Vue from "vue";

const HTTP = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
});

HTTP.interceptors.response.use(null, error => {
    if (error.response === undefined || error.message == "Network Error") {
        const vm = new Vue()
        if (router.currentRoute.fullPath !== '/login') {
            router.push('/login').then(() => {
                vm.$bvToast.toast('Die Verbindung zum Server ist fehlgeschlagen, bitte versuche es erneut', {
                    title: 'Verbindung fehlgeschlagen',
                    variant: 'danger',
                    appendToast: false
                });
            });
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
        }
        return Promise.reject(error);
    }
    switch (error.response.status) {
        case 403:
            break;
        case 401:
            if (localStorage.getItem("refreshToken")) {
                return AuthService.refresh()
                    .catch(() => {
                        if (router.currentRoute.fullPath !== '/login') {
                            router.push('/login');
                            localStorage.removeItem('token');
                        }
                    })
                    .then(response => {
                        if (response.data.access_token) {
                            localStorage.setItem('token', response.data.access_token);
                            localStorage.setItem('refreshToken', response.data.refresh_token);
                            error.config.headers.Authorization = "Bearer " + localStorage.getItem('token');
                            this.$router.go(this.$router.currentRoute);
                            return axios.request(error.config);
                        }
                    });
            }
        //case 404: router.push('/404'); break;
    }
    return Promise.reject(error);
});


HTTP.interceptors.request.use(function (config) {
    if (localStorage.getItem('token')) {
        config.headers.Authorization = "Bearer " + localStorage.getItem('token');
    }
    return config;
});
export default HTTP;