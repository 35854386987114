<template>
  <b-container>
    <div class="content">
      <b-card no-body v-if="customer !== null">
        <b-card-header>
          <b-row>
            <b-col>
              Kunde: <b>{{ this.customer.prename }} {{this.customer.lastname}}</b>
            </b-col>
            <b-col class="text-right">
              <b-checkbox switch v-model="customer.active" @change="toggleUser(id)"/>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body>
          <b-tabs>
            <b-tab title="Statistiken">
              <b-row>
                <b-col>
                  <b>Gebäude:</b> {{this.stats.buildings}}<br/>
                  <b>Einheiten:</b> {{this.stats.units}}<br/>
                  <b>Speicherverbrauch:</b> {{this.stats.spaceUsed}}<br/>
                </b-col>
                <b-col>
                  <b>Nebenkostenabrechnungen:</b> {{this.stats.billingOfIncidentals}}<br/>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="Stammdaten">
              Anonymisieren:
              <b-button @click="anonymize(id)"
                        size="sm" variant="danger"
                        v-b-tooltip="'Klicken Sie hier um sich den Kunden anzeigen zu lassen.'">
                <b-icon icon="trash" font-scale="1"></b-icon>
              </b-button>
              <b-table :items="properties">
                <template #cell(content)="data">
                  <b-input type="text" v-if="isEditableField(data.item.name)" v-model="customer[data.item.name]"/>
                  <span v-if="!isEditableField(data.item.name)">{{ data.item.content }}</span>
                </template>
              </b-table>
              <b-button variant="success" @click="updateUser()">Änderung speichern</b-button>
            </b-tab>
            <b-tab title="Kommentare">
              <Comment :entity-id="id" :entity-type="'User'"/>
            </b-tab>
          </b-tabs>
        </b-card-body>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import HTTP from "@/http";
import messageService from "@/services/message.service";
import Comment from "@/components/Comment";

export default {
  name: 'ShowCustomer',
  components: {Comment},

  data() {
    return {
      id: this.$route.params.id,
      customer: null,
      stats: null,
      properties: [],
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData: function () {
      HTTP.get("/admin/users/" + this.id).then(response => {
        this.customer = response.data;
        let keys = Object.keys(this.customer);
        keys.forEach(key => {
          this.properties.push({
            name: key,
            content: this.customer[key]
          })
        })
      });

      HTTP.get("/admin/users/stats/" + this.id).then(response => {
        this.stats = response.data;
      });
    },
    anonymize: function (userId) {
      if (!confirm("Nutzer anonymisieren?")) {
        return;
      }

      HTTP.put("/users/anonymize/" + userId).then(() => {
        messageService.success("Nutzer wurde anonymisiert", "Nutzer ist jetzt GDPR konform geleert");
        this.loadData();
      })
    },
    toggleUser: function (user) {
      HTTP.put("/users/active/" + user.id).then(() => {
        messageService.success("Toggle done", "Nutzer aktiviert/deaktiviert");
      })
    },
    updateUser: function () {
      HTTP.put("/users/" + this.customer.id, this.customer).then(() => {
        messageService.success("Update erfolgt", "Der Nutzer wurde erfolgreich bearbeitet");
      }).catch(response => {
        console.log(response);
        messageService.error("Fehlgeschlagen", "Es ist ein Fehler aufgetreten, siehe console");
      });
    },
    isEditableField: function (name) {
      return name !== 'id' && name !== 'lastUpdate' && name !== 'created' && name !== 'passwordResetToken'
          && name !== 'tokenValidUntil'
          && name !== 'active'
          && name !== 'keycloakId'
          && name !== 'contactInformation'
          && name !== 'bankingData'
          && name !== 'overdue';
    }
  }
}
</script>
<style scoped>
.tab-pane {
  padding-top: 16px;
  padding-bottom: 16px;
}
</style>