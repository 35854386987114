<template>
  <b-container>
    <div class="content">
      <b-card title="Dokumentekategorien">

            <b-button class="float-right mt-2" variant="success" :to="{name:'CreateUpdateDocumentFileCategory'}">
              Kategorie erstellen
            </b-button>
            <b-table class="card-table" striped hover :no-local-sorting="true" per-page="100"
                     :items="documentFileCategories.content"
                     :fields="categoryFields">
              <template #cell(active)="data" class="sm-col">
                <b-icon icon="check" v-if="data.item.active"/>
                <b-icon icon="x" v-if="!data.item.active"/>
              </template>
              <template #cell(actions)="data" class="sm-col">
                <b-button :to="{name:'UpdateDocumentFileCategory', params: {'id':data.item.id}}"
                          size="sm" variant="primary"
                          v-b-tooltip="'Klicken Sie hier um die Kategorie zu bearbeiten.'">
                  <b-icon icon="eye" font-scale="1"></b-icon>
                </b-button>
                <b-button @click="deleteDocumentFileCategory(data.item)"
                          size="sm" variant="danger" class="ml-2"
                          v-b-tooltip="'Klicken Sie hier um die Kategorie zu löschen.'">
                  <b-icon icon="trash" font-scale="1"></b-icon>
                </b-button>
              </template>
            </b-table>
            <RouterlessPageable v-if="documentFileCategories != null" :pageable="documentFileCategories" @change="loadTemplateCategories"/>
      </b-card>
    </div>
  </b-container>
</template>
<script>

import RouterlessPageable from "@/components/RouterlessPageable.vue";
import HTTP from "@/http";

export default {
  components: {RouterlessPageable},
  data() {
    return {
      documentFileCategories: {},
      categoryFields: [
        {
          key: 'id',
          label: 'id'
        },{
          key: 'name',
          label: 'Name'
        },
        {
          key: 'active',
          label: 'Aktiv'
        },
        {
          key: 'actions',
          label: 'Aktionen'
        }
      ]
    }
  },
  created() {
    this.loadDocumentFileCategories();
  },
  methods: {
    loadDocumentFileCategories: function (page = 0) {
      HTTP.get("/document-file-categories?sort=_id,desc&page=" + page + "&size=100", {
        headers: {
          'Accept': 'application/json'
        }
      }).then(response => {
        this.documentFileCategories = response.data;
      });
    },
    deleteDocumentFileCategory: function (item) {
      if (!confirm('Kategorie wirklich löschen?')) {
        return;
      }
      this.documentFileCategories.content.pop(item);
      HTTP.delete("/document-file-categories/" + item.id, {
        headers: {
          'Accept': 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast('Erfolgreich gelöscht', {title: 'Gelöscht'})
      })
    }
  }
}
</script>
<style scoped>
.colorCode{
  width: 20px;
  height: 20px;
  margin: auto;
  display: block;
  border-radius: 2px;
}
</style>