<template>
  <b-container>
    <div class="content">
      <b-card header="Vorlagenkategorie erstellen">
        <b-form @submit.prevent="save">
          <b-row>
            <b-col>
              <label>Name der Kategorie</label>
              <b-input v-model="templateCategory.name"/>
            </b-col>
            <b-col>
              <label>Sortierung der Kategorie (Ganzzahl)</label>
              <b-input v-model="templateCategory.sort" type="number"/>
            </b-col>
            <b-col>
              <label>Hintergrundfarbe</label>
              <b-input type="color" v-model="templateCategory.colorCode"/>
            </b-col>
          </b-row>
          <b-button variant="success" class="mt-2 float-right" type="submit">Speichern</b-button>
        </b-form>
      </b-card>
      <b-card header="Vorlagen in dieser Kategorie" v-if="id !== null">
        <b-table class="card-table" striped hover :no-local-sorting="true" per-page="100" :items="templates.content"
                 :fields="templateFields">
          <template #cell(actions)="data" class="sm-col">
            <b-button :to="{name:'UpdateTemplate', params: {'id':data.item.id}}"
                      size="sm" variant="primary"
                      v-b-tooltip="'Klicken Sie hier um sich das Template anzeigen zu lassen.'">
              <b-icon icon="eye" font-scale="1"></b-icon>
            </b-button>
            <b-button @click="download(data.item)"
                      size="sm" variant="success" class="ml-2"
                      v-b-tooltip="'Klicken Sie hier um den Template download zu starten.'">
              <b-icon icon="download" font-scale="1"></b-icon>
            </b-button>
            <b-button @click="deleteTemplate(data.item)"
                      size="sm" variant="danger" class="ml-2"
                      v-b-tooltip="'Klicken Sie hier um das Template zu löschen.'">
              <b-icon icon="trash" font-scale="1"></b-icon>
            </b-button>
          </template>
        </b-table>
        <RouterlessPageable v-if="templates != null" :pageable="templates" @change="loadTemplates"/>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import HTTP from "@/http";
import RouterlessPageable from "@/components/RouterlessPageable.vue";

export default {
  components: {RouterlessPageable},
  data() {
    return {
      id: null,
      templateCategory: {},
      templates: {},
      templateFields: [
        {
          key: 'id',
          label: 'id'
        },
        {
          key: 'name',
          label: 'Name der Vorlage'
        },
        {
          key: 'sort',
          label: 'Sortierung'
        },
        {
          key: 'type',
          label: 'Typ'
        },
        {
          key: 'actions',
          label: 'Aktionen'
        }
      ],
    }
  },
  created() {
    if (this.$route.params.id !== undefined) {
      this.id = this.$route.params.id;
      HTTP.get("/template-categories/" + this.id, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(result => this.templateCategory = result.data);

      this.loadTemplates();
    }
  },
  methods: {
    download: function (item) {
      HTTP.get("/templates/data/" + item.id, {responseType: 'blob'}).then(response => {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = item.filename;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    deleteTemplate: function (item) {
      if (!confirm('Template wirklich löschen?')) {
        return;
      }
      this.templates.content.pop(item);
      HTTP.delete("/templates/" + item.id, {
        headers: {
          'Accept': 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast('Erfolgreich gelöscht', {title: 'Gelöscht'})
      })
    },
    loadTemplates: function (page = 0) {
      HTTP.get("/templates?templateCategoryId=" + this.id + "&size=100&page=" + page, {
        headers: {
          'Accept': 'application/json'
        }
      }).then(response => {
        this.templates = response.data;
      });
    },
    save: function () {
      console.log(this.templateCategory.colorCode);
      if (this.id == null) {
        HTTP.post("/template-categories", this.templateCategory, {
          headers: {
            'Accept': 'application/json'
          }
        }).then(() => {
          this.$router.push({name: 'Templates'}).then(() => {
            this.$bvToast.toast('Vorlagenkategorie erfolgreich gespeichert', {
              title: 'Gespeichert',
              variant: 'success'
            });
          })
        }).catch(() => {
          this.$bvToast.toast('Vorlagenkategorie konnte nicht gespeichert werden', {
            title: 'Fehler',
            variant: 'danger'
          });
        })
        return;
      }

      HTTP.put("/template-categories/" + this.id, this.templateCategory, {
        headers: {
          'Accept': 'application/json'
        }
      }).then(() => {
        this.$router.push({name: 'Templates'}).then(() => {
          this.$bvToast.toast('Vorlagenkategorie erfolgreich gespeichert', {title: 'Gespeichert', variant: 'success'});
        })
      }).catch(() => {
        this.$bvToast.toast('Vorlagenkategorie konnte nicht gespeichert werden', {title: 'Fehler', variant: 'danger'});
      })
    }
  }
}
</script>
<style>

</style>