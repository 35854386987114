<template>
  <b-container>
    <div class="content">
      <b-card title="Partnerangebote">
        <b-button class="float-right mt-2" variant="success" :to="{name:'CreatePartnerOffers'}">
          Partnerangebot erstellen
        </b-button>
        <b-table class="card-table" striped hover :no-local-sorting="true" per-page="100"
                 :items="partnerOffers.content"
                 :fields="partnerOfferFields">
          <template #cell(active)="data" class="sm-col">
            <b-icon icon="check" v-if="data.item.active"/>
            <b-icon icon="x" v-if="!data.item.active"/>
          </template>
          <template #cell(actions)="data" class="sm-col">

            <b-button :to="{name:'EditPartnerOffers', params: {'id':data.item.id}}"
                      size="sm" variant="primary"
                      v-b-tooltip="'Klicken Sie hier um das Angebot zu bearbeiten.'">
              <b-icon icon="pencil" font-scale="1"></b-icon>
            </b-button>
            <b-button @click="deletePartnerOffer(data.item)"
                      size="sm" variant="danger" class="ml-2"
                      v-b-tooltip="'Klicken Sie hier um das Angebot zu löschen.'">
              <b-icon icon="trash" font-scale="1"></b-icon>
            </b-button>
          </template>
        </b-table>
        <RouterlessPageable v-if="partnerOffers != null" :pageable="partnerOffers"
                            @change="loadData"/>
      </b-card>
    </div>
  </b-container>
</template>
<script lang="ts">
import HTTP from "@/http";
import RouterlessPageable from "@/components/RouterlessPageable.vue";
import messageService from "@/services/message.service";

export default {
  name: 'PartnerOffers',
  components: {RouterlessPageable},
  data() {
    return {
      partnerOffers: {},
      partnerOfferFields: [
        {
          key: 'id',
          label: 'id'
        },
        {
          key: 'title',
          label: 'Titel des Angebots'
        },
        {
          key: 'active',
          label: 'Aktiv'
        },
        {
          key: 'actions',
          label: 'Aktionen'
        },
      ]
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData: function (page = 0) {
      HTTP.get('/partner-offers?sort=_id,desc&page='+page, {
        headers: {
          'Accept': 'application/json'
        }
      }).then(response => {
        this.partnerOffers = response.data;
      });
    },
    deletePartnerOffer: function (item) {
      if (confirm('Angebot wirklich löschen?')) {
        HTTP.delete('/partner-offers/' + item.id).then(() => {
          messageService.success('Gelöscht','Partnerangebot wurde erfolgreich gelöscht.');
          this.loadData();
        });
      }
    }
  }
}
</script>
<style scoped>
</style>