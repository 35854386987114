<template>
  <b-container>
    <div class="content">
      <b-card header="Partnerangebot erstellen">
        <b-form @submit.prevent="saveOffer">
          <b-row>
            <b-col>
              <label>Titel</label>
              <b-input v-model="partnerOffer.title" required placeholder="Bitte Titel des Angebots eintragen"/>
            </b-col>
            <b-col>
              <label>CTA Beschriftung</label>
              <b-input v-model="partnerOffer.callToActionText" required
                       placeholder="Bitte Beschriftung des Buttons eintragen"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>CTA Beschriftung</label>
              <b-textarea v-model="partnerOffer.text" required
                       placeholder="Bitte Text eintragen"/>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <label>Zielurl</label>
              <b-input v-model="partnerOffer.url" required placeholder="Bitte URL eintragen"/>
            </b-col>
            <b-col>
              <label>Aktiv bis</label>
              <b-datepicker v-model="partnerOffer.activeUntil"/>
            </b-col>
            <b-col>
              <label>Aktiv</label>
              <b-checkbox v-model="partnerOffer.active">Aktiv</b-checkbox>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <label>Bild auswählen <small>(Nur benötigt bei Erstellung)</small></label>
              <b-form-file placeholder="Bild auswählen" v-model="imageFile" :required="id === null"></b-form-file>
            </b-col>
            <b-col>
              <label>Titelbild auswählen <small>(Nur benötigt bei Erstellung)</small></label>
              <b-form-file placeholder="Titelbild auswählen" v-model="titleImageFile"></b-form-file>
            </b-col>
          </b-row>
          <b-button variant="success" class="mt-2 float-right" type="submit">Speichern</b-button>
        </b-form>
      </b-card>
    </div>
  </b-container>
</template>
<script lang="ts">
import HTTP from "@/http";
import messageService from "@/services/message.service";

export default {
  name: 'CreateUpdatePartnerOffer',
  data() {
    return {
      id: this.$route.params.id,
      titleImageFile: null,
      imageFile: null,
      partnerOffer: {}
    }
  },
  created() {
    if (this.id) {
      this.loadOffer(this.id);
    }
  },
  methods: {
    convertFileToBase64: async function (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64 = reader.result.replace(/^data:.+;base64,/, '');
          resolve(base64);
        };
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
      });
    },
    loadOffer: function (id) {
      HTTP.get('/partner-offers/' + id).then(response => {
        this.partnerOffer = response.data;
      })
    },
    saveOffer: async function () {

      if (this.titleImageFile) {
        this.partnerOffer.base64TitleImage = await this.convertFileToBase64(this.titleImageFile);
        this.partnerOffer.mimeTypeTitleImage = this.titleImageFile.type;
      }

      if (this.imageFile) {
        this.partnerOffer.base64Image = await this.convertFileToBase64(this.imageFile);
        this.partnerOffer.mimeTypeImage = this.imageFile.type;
      }

      if (this.id) {
        HTTP.put('/partner-offers/' + this.id, this.partnerOffer).then(() => {
          this.$router.push('/partner-offers').then(() => {
            messageService.success('Update erfolgt', 'Partnerangebot wurde angepasst und gespeichert');
          })
        })
      } else {
        HTTP.post('/partner-offers', this.partnerOffer).then(() => {
          this.$router.push('/partner-offers').then(() => {
            messageService.success('Speichern erfolgreich', 'Partnerangebot wurde gespeichert');
          })
        });
      }
    }
  }
}
</script>
<style scoped>
</style>