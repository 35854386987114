import Vue from 'vue'
import App from './App.vue'
import router from "@/config/router";
import store from "@/config/store";
import {BootstrapVue, IconsPlugin} from "bootstrap-vue";
import moment from "moment";

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.filter('germanDateTime', function (value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm')
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
