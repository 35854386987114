<template>
  <b-container>
    <div class="content">
      <b-card header="Vorlage erstellen">
        <b-form @submit.prevent="save">
          <b-row>
            <b-col>
              <label>Name der Vorlage</label>
              <b-input v-model="template.name" required placeholder="Bitte Namen der Vorlage eintragen"/>
            </b-col>
            <b-col>
              <label>Sortierung der Vorlage (Ganzzahl)</label>
              <b-input v-model="template.sort" type="number" placeholder="Bspw.: 2"/>
            </b-col>
            <b-col>
              <label>Kategorie</label>
              <b-select v-model="template.templateCategoryId" required>
                <b-select-option :value="null">Bitte Kategorie wählen</b-select-option>
                <b-select-option :value="category.id" v-for="category in categories" :key="category.id">
                  {{ category.name }}
                </b-select-option>
              </b-select>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <label>Datei auswählen <small>(Nur benötigt bei Erstellung)</small></label>
              <b-form-file placeholder="Datei auswählen" v-model="file" @change="setFilename" :required="id === null"></b-form-file>
            </b-col>
            <b-col>
              <label>Dateiname</label>
              <b-input v-model="template.filename" placeholder="Dateiname eingeben oder automatisch befüllen lassen"/>
            </b-col>
            <b-col>
              <label>Typ des Dokuments</label>
              <b-select v-model="template.type">
                <b-select-option :value="null">-- Bitte wählen --</b-select-option>
                <b-select-option value="DOCX">Word Dokument</b-select-option>
                <b-select-option value="PDF">PDF</b-select-option>
                <b-select-option value="IMAGE">Bilddatei</b-select-option>
              </b-select>
            </b-col>
          </b-row>
          <b-button variant="success" class="mt-2 float-right" type="submit">Speichern</b-button>
        </b-form>
      </b-card>
    </div>
  </b-container>
</template>
<script>
import HTTP from "@/http";

export default {
  created() {
    if (this.$route.params.id !== undefined) {
      this.id = this.$route.params.id;
      HTTP.get('/templates/' + this.id, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(result => this.template = result.data);
    }

    HTTP.get("/template-categories?size=10000").then(result => {
      this.categories = result.data.content;
    })
  },
  data() {
    return {
      id: null,
      template: {
        templateCategoryId: null,
        type: null
      },
      file: null,
      categories: []
    }
  },
  methods: {
    convertFileToBase64: async function () {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64 = reader.result.replace(/^data:.+;base64,/, '');
          resolve(base64);
        };
        reader.onerror = error => reject(error);
        reader.readAsDataURL(this.file);
      });
    },
    save: async function () {
      if(this.file) {
        this.template.base64 = await this.convertFileToBase64();
        this.template.mimeType = this.file.type;
      }

      if (this.id !== null) {
        HTTP.put('/templates/' + this.id, this.template, {
          headers: {'Content-Type': 'application/json'}
        }).then(() => {
          this.$router.push({name: 'Templates'}).then(() => {
            this.$bvToast.toast('Vorlage erfolgreich gespeichert', {title: 'Gespeichert', variant: 'success'});
          })
        }).catch(() => {
          this.$bvToast.toast('Vorlage konnte nicht gespeichert werden', {title: 'Fehler', variant: 'danger'});
        })
        return;
      }

      HTTP.post("/templates", this.template, {
        headers: {
          'Accept': 'application/json'
        }
      }).then(() => {
        this.$router.push({name: 'Templates'}).then(() => {
          this.$bvToast.toast('Vorlage erfolgreich gespeichert', {title: 'Gespeichert', variant: 'success'});
        })
      }).catch(() => {
        this.$bvToast.toast('Vorlage konnte nicht gespeichert werden', {title: 'Fehler', variant: 'danger'});
      })
    },
    setFilename: function (event) {
      if (this.template.filename === undefined || this.template.filename === '') {
        this.template.filename = event.target.files[0].name;
      }
    }
  }
}
</script>
<style>

</style>