<template>
  <div class="wrapper">
    <div class="form-signin card">
      <div class="card-header">Immolert Login</div>
      <div class="card-body">
        <img src="/immolert-icon.png" style="max-width:190px; margin: auto; display: block; margin-bottom: 10px"/>
        <b-form @submit.prevent="handleLogin">
          <b-form-group id="input-group-3" label="E-Mail Adresse:" label-for="inputEmail">
            <b-form-input type="email" id="inputEmail" v-model="user.username"
                          placeholder="E-Mail Adresse"></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-4" label="Passwort:" label-for="inputPassword">
            <b-form-input type="password" id="inputPassword" v-model="user.password"
                          placeholder="Passwort"></b-form-input>
          </b-form-group>
          <b-alert variant="danger" v-if="message!=null" show>{{ this.message }}</b-alert>
          <b-button class="btn btn-lg btn-primary btn-block" type="submit">Einloggen</b-button>
          <br/>
        </b-form>
      </div>
    </div>
  </div>

</template>

<script>

import VueJwtDecode from "vue-jwt-decode";

export default {
  name: 'Login',
  data() {
    return {
      user: {},
      loading: false,
      message: null
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;

      console.log(this.user);
      if (this.user.username && this.user.password) {
        localStorage.removeItem("refreshToken");
        this.$store.dispatch('auth/login', this.user).then(
            () => {
              let token = VueJwtDecode.decode(localStorage.getItem("token"));
              if(!token.resource_access["immolert-app"].roles.includes("ADMIN")) {
                this.loading = false;
                this.message = "Die eingegebenen Logindaten sind falsch";
                return;
              }
              this.$router.push({name: "Dashboard"});
            }).catch(
            error => {
              this.loading = false;

              if (error.response && error.response.status === 401) {
                this.message = "Die eingegebenen Logindaten sind falsch";
              } else {
                this.message =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
              }

            }
        );
      }
    }
  }
};
</script>

<style scoped>
html,
body {
  height: 100%;
}

.wrapper {
  background: url('/public/login-background.jpg') no-repeat center top;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  position: fixed;
  margin: 0;
  padding: 0;
}

body {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  margin: 0 auto;
  margin-top: 100px;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>
